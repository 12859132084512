import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    
    <h1>Giles Burdett M. Eng. (Hons) MBCS MACM</h1>
    <ol style={{ listStyleType: "none", marginLeft: 0 }}>
      <li>AWS Certified</li>
      <li>Principal</li>
      <li><a href="mailto:hello@cogniworks.co.uk" rel="author">hello@cogniworks.co.uk</a></li>
      <li><a href="tel:+447746781796" rel="author">+44 (0)7746 781 796</a></li>
      <li><a href="http://gilesburdett.com" rel="author external nofollow noopener">LinkedIn</a></li>
    </ol>
  </Layout>
)

export default IndexPage
